import { Component, Vue } from "vue-property-decorator";

import { Header,Footer } from "@/layout";
import { ClassModel } from "@/models";
import { TeCommon } from "../Common";

export class SearchForm {
    name = '';
    clid = '';
    sid = '';
    cid = '';
}

type response = { [key: string]: unknown };

@Component<ApTeacherClassIndexController>({
    components: {
        Header,Footer
    }
})
export default class ApTeacherClassIndexController extends Vue {
    private doubleClick = false;
    private searchForm = new SearchForm();
    private cityList: object = {};
    private schoolList: object = {};
    private schoolClassList: object = {};
    private classList: object = {};
    private num = 0;
    private page = 1;
    private perpage = 50;
    private maxPage = 0;
    private errorMsg = "";
    private tid = window.localStorage.getItem('teacher_tid') as string;
    private token = window.localStorage.getItem('teacherToken') as string;

    public async created() {
        this.cityList = await TeCommon.getTECity(this.tid); //取得該老師所屬班級的所有縣市
        this.getClassList();
    }

    //取得該老師所屬班級之該縣市分校列表
    private async getTESchool() {
        this.searchForm.sid = '';
        this.searchForm.clid = '';
        const cid = this.searchForm.cid;
        this.schoolList = await TeCommon.getTESchool(cid, this.tid);
    }

    //取得該老師所屬班級之該分校班級列表
    private async getTEClass() {
        this.searchForm.clid = '';
        const sid = this.searchForm.sid;
        this.schoolClassList = await TeCommon.getTEClass(sid, this.tid);
    }

    public async getClassList() {
        const item = {
            page: this.page,
            perpage: this.perpage,
            name: this.searchForm.name,
            clid: this.searchForm.clid,
            sid: this.searchForm.sid,
            cid: this.searchForm.cid,
            tid: this.tid,
            token: this.token,
        }
        const data = await ClassModel.teGetList(item) as response;
        TeCommon.checkToken(data.ERR_CODE as number);
        this.num = data.num as number;
        this.classList = data.list as object;
        this.maxPage = Math.ceil(this.num/this.perpage);
    }

    private getPageList(page: number) {
        this.page = (page < 1) ? 1 : (page > this.maxPage) ?  this.maxPage : page;
        this.getClassList();
    }
}